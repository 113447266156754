import React from 'react';
import '../NavBar.css';
import Logo from '../images/animal-free-logo4.png';


  



export default function NavBar() {


 if (window.location.pathname.endsWith("/")) {
  return (
    <nav className="navbar bg-light">
      <div class="nav-wrapper">
          
        
        <ul id="menu">
            <div class="about"><a type="submit" href="/about">ABOUT</a></div>
            
            <div class="shop"><a type="submit" href="https://shop.animalfree.co">Shop</a></div>
        </ul>
      </div>
    </nav>
  )
  } else
  return (
    <nav className="navbar bg-light">
      <div class="nav-wrapper">
          
        
        <ul id="menu">
            <div class="about" id="about"><a type="submit" href="/about">ABOUT</a></div>
            <a id="navLogo" href="/"><img class="logo" id="logo" src={Logo} alt="Animalfree Logo"></img></a>
            <div class="shop"><a type="submit" href="https://shop.animalfree.co">Shop</a></div>
        </ul>
      </div>
    </nav>
    
  )
  
}

