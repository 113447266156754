
import {Link} from 'react-router-dom'
import emptyGrocery from '../images/groceries.png';
//const { image_search } = require('../duckduckgo-images-api/src/api')
import { image_search } from 'duckduckgo-images-api';

const result_image = emptyGrocery;


export default function SearchCard(props) {
    
    const controller = new AbortController();
    const {results} = props
    const squery = results.brandName + "+" + results.description;
    let foodID = "food-image"+results.description+results.brandName;
    let foodIMG;

    if (results.brandName == null) {
    } else {
    image_search({query: squery, iterations: 0}).then(results=>give(results))
    }


    const handleClick = (clickResults) => {
        props.searchFood(clickResults)
    }


    // Iterate over the results
    
    function give(results) {

        results.forEach(element => {
            if (element.width == element.height) {
                
                    document.getElementById(foodID).src = element.image;
                    
                    
                    foodIMG = element.image;
                    //updateFood(element.image)
                    
                }

            });
        }
          
/*
    function updateFood (newImage) {
        foodIMG=newImage;

        document.getElementById(foodID+"2").onClick = function() {handleClick(results, foodIMG)};
        document.getElementById(foodID+"2").to = '/check/' + results.fdcId + "?" + foodIMG;
        document.getElementById(foodID+"2").href = '/check/' + results.fdcId + "?" + foodIMG;
        //document.getElementById(foodID+"2").id = foodIMG; //`/check/${results.fdcId+"?"+foodIMG}`;
        
        //document.getElementById(foodID+"2").onClick = handleClick(results, foodIMG);
    }
*/
    return (
    <>
        <div className="row">
            <div className="col-sm-6 w-100">
                <div className="card mb-2">
                    <div className="card-body">
                        <center><img src={result_image} width={300} id={foodID}></img></center>
                        <h5 className="card-title">{results.description}</h5>

                        {/* Displays brand name if it is provided */}
                        {results.brandName ? <p className='text-muted fst-italic'>Brand: {results.brandName}</p> : null}

                        {/* Display item description if provided otherwise prints "No description provided" */}
                        <p className="card-text">{String(results.additionalDescriptions) !== "undefined" && String(results.additionalDescriptions) !== "" ?
                        String(results.additionalDescriptions).replaceAll(";", ", ") : "No description provided"}</p>

                        {/* Check button */}
                        <Link to={`/check/${results.fdcId}`} className="btn btn-dark w- p-10 d-block mx-auto" id={foodID+"2"} onClick={() => handleClick(results)}>Check</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
    
  )

}
